<template>
    <div>
        <a :href="'https://mobile.wendu.com/question?questionId='+this.id" target="_blank">
            <div class="qrcode" ref="qrCodeUrl"></div>
        </a>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'
    import {request} from "../../../../public/js/common-vue";

    export default {
        name: "QrCode",
        data(){
            return{
                id: request("id")
            }
        },
        methods: {
            creatQrCode() {
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: 'https://mobile.wendu.com/exercise?paperId='+this.id,
                    width: 260,
                    height: 260,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
        },
        mounted() {
            this.creatQrCode();
        }
    }
</script>

<style scoped>
.qrcode{
    display: block;
    margin-top: 25px;
    margin-left: 50px;
}
</style>
